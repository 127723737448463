<!--suppress ALL -->
<template>
  <svg
    version="1.1"
    id="_x2014_ÎÓÈ_x5F_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 381.5 540"
    style="enable-background:new 0 0 381.5 540;"
    xml:space="preserve"
  >
    <g>
      <rect x="60" y="119.25" class="st0" width="121.5" height="1.5" />
    </g>
    <g>
      <g>
        <path
          class="st0"
          d="M196.5,100.75H195V100c0-9.718-8.802-19.778-17.315-29.506C168.991,60.557,160,50.282,160,40v-0.75h1.5V40
			c0,9.718,8.802,19.778,17.314,29.506C187.509,79.443,196.5,89.718,196.5,100V100.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M196.5,100.75H195V100c0-9.483-26.444-19.557-52.017-29.299C115.736,60.321,90,50.517,90,40v-0.75h1.5V40
			c0,9.483,26.444,19.557,52.017,29.299C170.764,79.679,196.5,89.483,196.5,100V100.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M196.5,100.75H195V100c0-10.517,25.736-20.321,52.983-30.701C273.557,59.557,300,49.483,300,40v-0.75h1.5V40
			c0,10.517-25.736,20.321-52.983,30.701C222.943,80.443,196.5,90.517,196.5,100V100.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M196.5,100.75H195V100c0-10.282,8.991-20.557,17.685-30.494C221.198,59.778,230,49.718,230,40v-0.75h1.5V40
			c0,10.282-8.991,20.557-17.686,30.494C205.302,80.222,196.5,90.282,196.5,100V100.75z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          class="st0"
          d="M161.5,200.75H160V200c0-10.282,8.991-20.557,17.685-30.494C186.198,159.778,195,149.718,195,140v-0.75h1.5
			V140c0,10.282-8.991,20.557-17.686,30.494C170.302,180.222,161.5,190.282,161.5,200V200.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M91.5,200.75H90V200c0-10.517,25.736-20.321,52.983-30.701C168.556,159.557,195,149.483,195,140v-0.75h1.5
			V140c0,10.517-25.736,20.321-52.983,30.701C117.944,180.443,91.5,190.517,91.5,200V200.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M301.5,200.75H300V200c0-9.483-26.443-19.557-52.017-29.299C220.736,160.321,195,150.517,195,140v-0.75h1.5
			V140c0,9.483,26.443,19.557,52.017,29.299C275.764,179.679,301.5,189.483,301.5,200V200.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M231.5,200.75H230V200c0-9.718-8.802-19.778-17.315-29.506C203.991,160.557,195,150.282,195,140v-0.75h1.5
			V140c0,9.718,8.802,19.778,17.314,29.506c8.694,9.937,17.686,20.212,17.686,30.494V200.75z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          class="st0"
          d="M161.5,400.75H160V400c0-9.565-17.601-19.623-34.622-29.349C107.185,360.255,90,350.435,90,340v-0.75h1.5V340
			c0,9.565,17.601,19.623,34.622,29.349C144.315,379.745,161.5,389.565,161.5,400V400.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M231.5,400.75H230V400c0-9.565-17.601-19.623-34.622-29.349C177.185,360.255,160,350.435,160,340v-0.75h1.5
			V340c0,9.565,17.601,19.623,34.622,29.349C214.315,379.745,231.5,389.565,231.5,400V400.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M231.5,400.75H230V400c0-10.435,17.185-20.255,35.378-30.651C282.399,359.623,300,349.565,300,340v-0.75h1.5
			V340c0,10.435-17.185,20.255-35.378,30.651C249.101,380.377,231.5,390.435,231.5,400V400.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M161.5,400.75H160V400c0-10.435,17.185-20.255,35.378-30.651C212.399,359.623,230,349.565,230,340v-0.75h1.5
			V340c0,10.435-17.185,20.255-35.378,30.651C179.101,380.377,161.5,390.435,161.5,400V400.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M231.5,400.75H230V400c0-9.434-35.309-19.522-69.456-29.279C124.266,360.356,90,350.566,90,340v-0.75h1.5V340
			c0,9.434,35.309,19.522,69.456,29.279C197.234,379.644,231.5,389.434,231.5,400V400.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M161.5,400.75H160V400c0-10.566,34.266-20.356,70.544-30.721C264.69,359.522,300,349.434,300,340v-0.75h1.5
			V340c0,10.566-34.266,20.356-70.544,30.721C196.809,380.478,161.5,390.566,161.5,400V400.75z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          class="st0"
          d="M196.5,500.75H195V500c0-9.718-8.802-19.777-17.315-29.506C168.991,460.557,160,450.282,160,440v-0.75h1.5
			V440c0,9.718,8.802,19.777,17.314,29.506c8.695,9.938,17.686,20.212,17.686,30.494V500.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M196.5,500.75H195V500c0-10.282,8.991-20.557,17.685-30.494C221.198,459.777,230,449.718,230,440v-0.75h1.5
			V440c0,10.282-8.991,20.557-17.686,30.494C205.302,480.223,196.5,490.282,196.5,500V500.75z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          class="st0"
          d="M161.5,300.75H160V300c0-9.565-17.601-19.623-34.622-29.349C107.185,260.255,90,250.435,90,240v-0.75h1.5V240
			c0,9.565,17.601,19.623,34.622,29.349C144.315,279.745,161.5,289.565,161.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M231.5,300.75H230V300c0-9.565-17.601-19.623-34.622-29.349C177.185,260.255,160,250.435,160,240v-0.75h1.5
			V240c0,9.565,17.601,19.623,34.622,29.349C214.315,279.745,231.5,289.565,231.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M301.5,300.75H300V300c0-9.565-17.601-19.623-34.622-29.349C247.185,260.255,230,250.435,230,240v-0.75h1.5
			V240c0,9.565,17.601,19.623,34.622,29.349C284.315,279.745,301.5,289.565,301.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M91.5,300.75H90V300c0-10.435,17.185-20.255,35.378-30.651C142.399,259.623,160,249.565,160,240v-0.75h1.5
			V240c0,10.435-17.185,20.255-35.378,30.651C109.101,280.377,91.5,290.435,91.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M161.5,300.75H160V300c0-10.435,17.185-20.255,35.378-30.651C212.399,259.623,230,249.565,230,240v-0.75h1.5
			V240c0,10.435-17.185,20.255-35.378,30.651C179.101,280.377,161.5,290.435,161.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M231.5,300.75H230V300c0-10.435,17.185-20.255,35.378-30.651C282.399,259.623,300,249.565,300,240v-0.75h1.5
			V240c0,10.435-17.185,20.255-35.378,30.651C249.101,280.377,231.5,290.435,231.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M231.5,300.75H230V300c0-9.434-35.309-19.522-69.456-29.279C124.266,260.356,90,250.566,90,240v-0.75h1.5V240
			c0,9.434,35.309,19.522,69.456,29.279C197.234,279.644,231.5,289.434,231.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M301.5,300.75H300V300c0-9.434-35.31-19.522-69.456-29.279C194.266,260.356,160,250.566,160,240v-0.75h1.5
			V240c0,9.434,35.309,19.522,69.456,29.279C267.234,279.644,301.5,289.434,301.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M161.5,300.75H160V300c0-10.566,34.266-20.356,70.544-30.721C264.69,259.522,300,249.434,300,240v-0.75h1.5
			V240c0,10.566-34.266,20.356-70.544,30.721C196.809,280.478,161.5,290.566,161.5,300V300.75z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M91.5,300.75H90V300c0-10.566,34.266-20.356,70.544-30.721C194.691,259.522,230,249.434,230,240v-0.75h1.5
			V240c0,10.566-34.266,20.356-70.544,30.721C126.809,280.478,91.5,290.566,91.5,300V300.75z"
        />
      </g>
    </g>
    <g>
      <g>
        <g>
          <rect y="69.25" class="st0" width="10.5" height="1.5" />
        </g>
        <g>
          <path
            class="st0"
            d="M355.262,70.75h-18.737v-1.5h18.737V70.75z M320.786,70.75h-18.738v-1.5h18.738V70.75z M286.31,70.75
				h-18.738v-1.5h18.738V70.75z M251.834,70.75h-18.738v-1.5h18.738V70.75z M217.357,70.75h-18.738v-1.5h18.738V70.75z
				 M182.881,70.75h-18.738v-1.5h18.738V70.75z M148.405,70.75h-18.738v-1.5h18.738V70.75z M113.929,70.75H95.19v-1.5h18.738V70.75z
				 M79.453,70.75H60.714v-1.5h18.738V70.75z M44.977,70.75H26.238v-1.5h18.738V70.75z"
          />
        </g>
        <g>
          <rect x="371" y="69.25" class="st0" width="10.5" height="1.5" />
        </g>
      </g>
      <g>
        <g>
          <rect y="169.25" class="st0" width="10.5" height="1.5" />
        </g>
        <g>
          <path
            class="st0"
            d="M355.262,170.75h-18.737v-1.5h18.737V170.75z M320.786,170.75h-18.738v-1.5h18.738V170.75z M286.31,170.75
				h-18.738v-1.5h18.738V170.75z M251.834,170.75h-18.738v-1.5h18.738V170.75z M217.357,170.75h-18.738v-1.5h18.738V170.75z
				 M182.881,170.75h-18.738v-1.5h18.738V170.75z M148.405,170.75h-18.738v-1.5h18.738V170.75z M113.929,170.75H95.19v-1.5h18.738
				V170.75z M79.453,170.75H60.714v-1.5h18.738V170.75z M44.977,170.75H26.238v-1.5h18.738V170.75z"
          />
        </g>
        <g>
          <rect x="371" y="169.25" class="st0" width="10.5" height="1.5" />
        </g>
      </g>
      <g>
        <g>
          <rect y="469.25" class="st0" width="10.5" height="1.5" />
        </g>
        <g>
          <path
            class="st0"
            d="M355.262,470.75h-18.737v-1.5h18.737V470.75z M320.786,470.75h-18.738v-1.5h18.738V470.75z M286.31,470.75
				h-18.738v-1.5h18.738V470.75z M251.834,470.75h-18.738v-1.5h18.738V470.75z M217.357,470.75h-18.738v-1.5h18.738V470.75z
				 M182.881,470.75h-18.738v-1.5h18.738V470.75z M148.405,470.75h-18.738v-1.5h18.738V470.75z M113.929,470.75H95.19v-1.5h18.738
				V470.75z M79.453,470.75H60.714v-1.5h18.738V470.75z M44.977,470.75H26.238v-1.5h18.738V470.75z"
          />
        </g>
        <g>
          <rect x="371" y="469.25" class="st0" width="10.5" height="1.5" />
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle class="st0" cx="195.75" cy="120" r="20" />
      </g>
      <g>
        <circle class="st0" cx="160.75" cy="220" r="20" />
      </g>
      <g>
        <circle class="st0" cx="90.75" cy="220" r="20" />
      </g>
      <g>
        <circle class="st0" cx="230.75" cy="220" r="20" />
      </g>
      <g>
        <circle class="st0" cx="300.75" cy="220" r="20" />
      </g>
      <g>
        <circle class="st0" cx="160.75" cy="320" r="20" />
      </g>
      <g>
        <circle class="st0" cx="90.75" cy="320" r="20" />
      </g>
      <g>
        <circle class="st0" cx="230.75" cy="320" r="20" />
      </g>
      <g>
        <circle class="st0" cx="300.75" cy="320" r="20" />
      </g>
      <g>
        <circle class="st0" cx="160.75" cy="420" r="20" />
      </g>
      <g>
        <circle class="st0" cx="230.75" cy="420" r="20" />
      </g>
    </g>
    <g>
      <circle class="st0" cx="195.75" cy="520" r="20" />
      <polygon
        class="st1"
        points="195.75,503.876 202.75,516 209.75,528.124 195.75,528.124 181.75,528.124 188.75,516 	"
      />
    </g>
    <path
      class="st0"
      d="M40.75,100c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20s20-8.954,20-20
	C60.75,108.954,51.796,100,40.75,100z M40.75,128.124h-14l7-12.124l7-12.124l7,12.124l7,12.124H40.75z"
    />
    <path
      class="st0"
      d="M90.75,0c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20s20-8.954,20-20C110.75,8.954,101.796,0,90.75,0z
	 M90.75,35l-15-15l15-15l15,15L90.75,35z"
    />
    <path
      class="st0"
      d="M160.75,0c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20s20-8.954,20-20C180.75,8.954,171.796,0,160.75,0z
	 M160.75,33.5c-7.456,0-13.5-6.044-13.5-13.5s6.044-13.5,13.5-13.5s13.5,6.044,13.5,13.5S168.206,33.5,160.75,33.5z"
    />
    <path
      class="st0"
      d="M230.75,0c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20s20-8.954,20-20C250.75,8.954,241.796,0,230.75,0z
	 M230.75,28.124h-14l7-12.124l7-12.124l7,12.124l7,12.124H230.75z"
    />
    <path
      class="st0"
      d="M300.75,0c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20
	C320.75,8.954,311.796,0,300.75,0z M311.357,30.607h-21.213V9.393h21.213V30.607z"
    />
  </svg>
</template>

<script>
export default {
  name: "NaturalLanguageProcessingChart"
};
</script>

<style scoped>
.st0 {
  fill: #072c5c;
}
.st1 {
  fill: #ffffff;
}
</style>
