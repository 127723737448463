<template>
  <div class="root_cont">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h4 style="font-weight: 800;">{{ t.head_1 }}</h4>
          <p class="mt-4">
            {{ t.h_1 }}
          </p>
          <p class="mt-4">
            {{ t.h_2 }}
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="chart_cont">
            <NaturalLanguageProcessingChart></NaturalLanguageProcessingChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head_1": "Natural Language Processing",
    "h_1": "By building upon the latest advances in Natural Language Processing, such as Facebook's FastText and Google's BERT models for vectorised representation of text, we have succeeded in extracting highly structured information buried in noisy (\"dirty\") free text.",
    "h_2": "We do this by extracting information from unstructured records with our Fine-Grained Named Entity Recognition Module and categorising land parcel related records with a multi-class neural network classifier."
  },
  "hr": {
    "head_1": "Obrada prirodnog jezika",
    "h_1": "Nadograđivanjem najsuvremenijih alata za obradu prirodnog jezika poput Facebookovog FastTexta i Googleovih BERT modela za vektorizirani prikaz teksta, uspješno izvlačimo iznimno složene podatke zakopane u neurednom slobodnom tekstu.",
    "h_2": "Neuredne zapise o zemljištima dovodimo u red pomoću modula preciznog prepoznavanja imenovanih entiteta (NER) te ih kategoriziramo koristeći neuralne mreže za višeklasnu klasifikaciju."
  },
  "es": {
    "head_1": "Procesamiento del lenguaje natural",
    "h_1": "Aprovechando los últimos avances en el procesamiento del lenguaje natural, como FastText de Facebook y los modelos BERT de Google para la representación vectorial de texto, hemos logrado extraer información altamente estructurada enterrada en texto libre ruidoso (\"sucio\").",
    "h_2": "Lo hacemos extrayendo información de registros no estructurados a través de nuestro módulo de reconocimiento detallado de entidades nombrados (NER) y clasificando los registros relacionados con parcelas mediante un clasificador de múltiples clases basado en redes neuronales."
  }
}
//</i18n>

import NaturalLanguageProcessingChart from "./charts/NaturalLanguageProcessingChart";
export default {
  name: "NaturalLanguageProcessing",
  components: { NaturalLanguageProcessingChart },
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.root_cont {
  background-color: #fffff3;
  color: rgb(4, 43, 93);
  padding: 60px 0;
}

.chart_cont {
  padding-top: 30px;
  padding-left: 70px;
  padding-right: 70px;
}

@media (max-width: 576px) {
  .chart_cont {
    padding: 20px;
  }
}
</style>
