<template>
  <div class="root_cont">
    <div class="container">
      <div class="row text-left">
        <div class="col-md-5 col-sm-12">
          <div class="chart_cont">
            <DeepNeuralNetworksChart :active="true"></DeepNeuralNetworksChart>
          </div>
        </div>
        <div class="col-md-7 col-sm-12">
          <h4 style="font-weight: 800;">
            <span v-html="t.head_1"></span>
       </h4>
          <p class="mt-4">
            <span v-html="t.h_1"></span>
          </p>
          <p class="mt-4">
            <span v-html="t.h_2"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head_1": "Deep Neural Networks",
    "h_1": "As the current, most sophisticated of Machine Learning methods, the use of Neural Networks underpins our approach to solving extreme complexity.",
    "h_2": "From organising and cleaning unstructured textual data on real estate, identifying and linking records, to developing predictive models for business processes, our proprietary Deep Learning algorithms are what makes Terra Adriatica’s mission possible."
  },
  "hr": {
    "head_1": "Duboke neuronske mreže",
    "h_1": "Kao trenutno najsofisticiranija metoda strojnog učenja, metoda dubokih neuronskih mreža podcrtava naš jedinstven pristup rješavanju iznimno složenih problema.",
    "h_2": "Od organizacije i pročišćavanja nestrukturiranih tekstualnih podataka o nekretninama, preko prepoznavanja i povezivanja zapisa, do razvijanja prediktivnih modela za poslovne procese - naši <i>Deep Learning</i> algoritmi čine misiju Terra Adriatice mogućom."
  },
  "es": {
    "head_1": "Redes neuronales profundas",
    "h_1": "Como el método de aprendizaje automático más sofisticado y más reciente, el uso de redes neuronales respalda nuestro enfoque para resolver complejidad extrema.",
    "h_2": "Desde la organización y limpieza de datos textuales no estructurados sobre bienes raíces, la identificación y vinculación de registros, hasta el desarrollo de modelos predictivos para procesos empresariales, nuestros algoritmos propios de aprendizaje profundo son lo que hace posible la misión de Terra Adriatica."
  }
}
//</i18n>

import DeepNeuralNetworksChart from "./charts/DeepNeuralNetworksChart";
export default {
  name: "DeepNeuralNetworks",
  components: { DeepNeuralNetworksChart },
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.chart_cont {
}

.root_cont {
  background-color: rgb(4, 43, 93);
  color: #fffff3;
  padding: 60px 0;
}
</style>
