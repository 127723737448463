<template>
  <div class="root_cont">
    <TechnologyGridAnimation></TechnologyGridAnimation>

    <div class="t_cont">
      <div class="blue_bg">
        <div class="container">
          <div class="t_text">
            <h2>
              <span>{{ t.head_1 }}</span>
              <span> {{ t.head_2 }}</span>
            </h2>
            <h5 class="mt-4">
              {{ t.h_1 }}
            </h5>
            <h5 class="mt-4 font-weight-normal">
              {{ t.h_2 }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head_1": "WE USE ADVANCED TECHNOLOGY",
    "head_2": "TO SOLVE COMPLEX PROBLEMS",
    "h_1": "Our platform utilises state-of-the-art Artificial Intelligence tools and Machine Learning solutions to navigate, clean and connect tens of millions of historical, unstructured and disparate data records to provide clarity.",
    "h_2": "We then apply Process Automation to navigate the complex legal and administrative tasks of establishing clean title ahead of sale."
  },
  "hr": {
    "head_1": "KORISTIMO NAPREDNU TEHNOLOGIJU",
    "head_2": "ZA RJEŠAVANJE SLOŽENIH PROBLEMA",
    "h_1": "Naša platforma koristi najnovija dostignuća Umjetne Inteligencije (UI) i strojnog učenja. Njome pronalazimo, čistimo i povezujemo desetke milijuna povijesnih, nestrukturiranih i neusklađenih zapisa i na taj način ih pretvaramo u  korisne informacije.",
    "h_2": "Zatim primjenjujemo automatizaciju procesa kako bismo upravljali složenim pravnim i administrativnim postupcima za uspostavljanje čistog vlasništva nad zemljištem prije prodaje."
  },
  "es": {
    "head_1": "UTILIZAMOS TECNOLOGÍA AVANZADA",
    "head_2": "PARA RESOLVER PROBLEMAS COMPLEJOS",
    "h_1": "Nuestra plataforma utiliza herramientas de inteligencia artificial y soluciones de aprendizaje automático de última generación para navegar por, limpiar y conectar decenas de millones de registros de datos históricos, no estructurados y dispares generando, así, información útil.",
    "h_2": "Luego aplicamos la automatización de procesos para navegar por las complejas tareas legales y administrativas de establecer un título limpio antes de la venta."
  }
}
//</i18n>

import TechnologyGridAnimation from "./TechnologyGridAnimation";

export default {
  name: "TopContTechnology",
  components: { TechnologyGridAnimation },
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.root_cont {
  position: relative;
}

.t_cont {
  padding-top: 250px;
  width: 100%;
  text-align: center;
  color: #fffff3;
  position: relative;
  z-index: 3;
}

.blue_bg {
  background-color: rgba(4, 43, 93, 0.8);
}

.t_text {
  padding: 40px 0;
  line-height: 10px;
}

h2 span {
  display: block;
}

h5 {
  line-height: 1.3;
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  h2 span {
    display: inline;
  }
}

@media (max-width: 576px) {
  .t_cont {
    padding-top: 0;
  }

  .blue_bg {
    background-color: rgba(4, 43, 93, 1);
  }
}
</style>
