<template>
  <div>
    <TopContTechnology></TopContTechnology>
    <LegalProcessAutomation></LegalProcessAutomation>
    <DeepNeuralNetworks></DeepNeuralNetworks>
    <NaturalLanguageProcessing></NaturalLanguageProcessing>
    <SemanticSearch></SemanticSearch>
  </div>
</template>

<script>
import TopContTechnology from "../../components/web/technology/TopContTechnology";
import LegalProcessAutomation from "../../components/web/technology/LegalProcessAutomation";
import DeepNeuralNetworks from "../../components/web/technology/DeepNeuralNetworks";
import NaturalLanguageProcessing from "../../components/web/technology/NaturalLanguageProcessing";
import SemanticSearch from "../../components/web/technology/SemanticSearch";
export default {
  name: "Technology",
  components: {
    SemanticSearch,
    NaturalLanguageProcessing,
    DeepNeuralNetworks,
    LegalProcessAutomation,
    TopContTechnology
  }
};
</script>

<style scoped></style>
