<!--suppress ALL -->
<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 280.4 349.2" style="enable-background:new 0 0 280.4 349.2;" xml:space="preserve">

<g>
	<line class="st0" x1="46.5" y1="275.9" x2="46.5" y2="273.7"/>
	<line class="st0" x1="48.5" y1="276.5" x2="49.6" y2="274.5"/>
	<line class="st0" x1="49.8" y1="277.8" x2="51.8" y2="276.7"/>
	<line class="st0" x1="50.2" y1="279.6" x2="52.4" y2="279.6"/>
	<line class="st0" x1="44.9" y1="276.5" x2="43.8" y2="274.5"/>
	<path class="st0" d="M31.2,271.4l-6.6,6.6c-0.4-0.4-1-0.4-1.4,0l-0.3,0.3c-0.4,0.4-0.4,1,0,1.4l2,2c0.4,0.4,1,0.4,1.4,0l0.3-0.3
		c0.4-0.4,0.4-1,0-1.4l6.6-6.6"/>
	<path class="st0" d="M29.7,268.1l1,1.1h1.4l3.3,3.3v1.4l1.1,1c0.4,0.4,1,0.4,1.4,0l4.1-4.1c0.4-0.4,0.4-0.9,0-1.3l-1-1h-1.4
		l-3.5-3.5v-1.4l-1-1c-0.4-0.4-1-0.4-1.3,0l-4.1,4.1C29.3,267.1,29.3,267.7,29.7,268.1z"/>
	<line class="st0" x1="24.6" y1="277.9" x2="25.6" y2="279"/>
	<line class="st0" x1="36.2" y1="265" x2="34.8" y2="266.3"/>
	<line class="st0" x1="39.6" y1="268.4" x2="38.3" y2="269.7"/>
	<line class="st0" x1="33.1" y1="268" x2="32.1" y2="269.1"/>
	<line class="st0" x1="36.6" y1="271.5" x2="35.5" y2="272.5"/>
	<path class="st0" d="M47.7,281.8h-0.5v-1.3c0-0.8-0.6-1.7-1.4-1.7h-8.2c-0.8,0-1.5,0.9-1.5,1.7v1.3h-0.4c-0.8,0-1.8,0.3-1.8,1.1
		v2.6h15.6V283C49.5,282.2,48.5,281.8,47.7,281.8z"/>
	<path class="st0" d="M36.1,281.8v-1.3c0-0.8,0.7-1.7,1.5-1.7h8.2c0.8,0,1.4,0.9,1.4,1.7v1.3"/>
	<path class="st0" d="M41.3,281.8h-5.6c-0.8,0-1.8,0.3-1.8,1.1v2.6h15.6V283c0-0.8-0.9-1.1-1.7-1.1H45"/>
	<path class="st0" d="M32.4,261v-8.2c0-0.8,0.7-1.5,1.5-1.5h17.8l5.2,5.2v27.5c0,0.8-0.7,1.5-1.5,1.5h-3.7"/>
	<polyline class="st0" points="54.7,257.3 51,257.3 51,253.6 	"/>
</g>
<g>
	<g>
		<path class="st0" d="M46.1,64c0.3-1.7-1.2-3.1-2.8-2.8c-1,0.2-1.9,1-2,2c-0.3,1.7,1.2,3.1,2.8,2.8C45.1,65.8,46,65,46.1,64z"/>
		<path class="st0" d="M45.9,57.3c1.4,0,2.6,1.2,2.6,2.6v7.4c0,1.4-1.2,2.6-2.6,2.6h-4.4c-1.4,0-2.6-1.2-2.6-2.6v-7.4
			c0-1.4,1.2-2.6,2.6-2.6H45.9z"/>
		<path class="st0" d="M48.5,59.7h1.2c1.4,0,2.6,1.2,2.6,2.6V72c0,1.4-1.2,2.6-2.6,2.6h-12c-1.4,0-2.6-1.2-2.6-2.6v-9.7
			c0-1.4,1.2-2.6,2.6-2.6h1.2"/>
		<line class="st0" x1="56.9" y1="100.1" x2="48.5" y2="78.1"/>
		<line class="st0" x1="59.6" y1="100.1" x2="49.9" y2="74.6"/>
		<line class="st0" x1="38.9" y1="78.1" x2="30.8" y2="100.1"/>
		<line class="st0" x1="37.6" y1="74.6" x2="28.1" y2="100.1"/>
		<path class="st0" d="M38.9,74.6v6.8c0,1.4,1.2,2.6,2.6,2.6h4.4c1.4,0,2.6-1.2,2.6-2.6v-6.8"/>
	</g>
	<line class="st0" x1="42.5" y1="100.1" x2="42.5" y2="84"/>
	<line class="st0" x1="45" y1="100.1" x2="45" y2="84"/>
</g>
<g>
	<g>
		<line class="st0" x1="231.2" y1="83.7" x2="228.1" y2="83.7"/>
		<polyline class="st0" points="237.7,90 236.6,90 228,90 		"/>
		<path class="st0" d="M239.8,90h1.4h6.7c1.2,0,2.2-1,2.2-2.2v-4.2v-4.9v-4.2c0-1.2-1-2.2-2.2-2.2H242h-13.9"/>
		<line class="st0" x1="235.2" y1="78.8" x2="228.1" y2="78.8"/>
		<line class="st0" x1="238.4" y1="78.8" x2="245.5" y2="78.8"/>
		<line class="st0" x1="248.1" y1="78.8" x2="250" y2="78.8"/>
		<line class="st0" x1="242" y1="72.4" x2="242" y2="78.8"/>
		<path class="st0" d="M234.2,83.7h0.2c1.2,0,2.2,1,2.2,2.2V90"/>
		<line class="st0" x1="241.1" y1="83.7" x2="242.8" y2="83.7"/>
		<line class="st0" x1="245.3" y1="83.7" x2="250" y2="83.7"/>
		<line class="st0" x1="241.1" y1="83.7" x2="241.1" y2="90"/>
	</g>
	<path class="st0" d="M219,63.7c0-2.5,2-4.6,4.6-4.6c2.5,0,4.6,2,4.6,4.6"/>
	<line class="st0" x1="219" y1="63.7" x2="219" y2="89.6"/>
	<line class="st0" x1="228.1" y1="63.7" x2="228.1" y2="89.5"/>
	<path class="st0" d="M223.5,94.2c-2.5,0-4.6-2-4.6-4.6c0-2.1,1.5-3.9,3.4-4.4c0.4-0.1,0.7-0.1,1.1-0.1c2.4,0,4.3,1.8,4.6,4.1"/>
	<path class="st0" d="M223.5,91.8c0.5,0.4,1.1,0.6,1.7,0.6c1.6,0,2.8-1.3,2.8-2.8"/>
	<path class="st0" d="M228.1,68.3h24.6c1.2,0,2.2,1,2.2,2.2V92c0,1.2-1,2.2-2.2,2.2h-29.1"/>
	<line class="st0" x1="223.5" y1="91.8" x2="223.5" y2="85.1"/>
</g>
<g>
	<polyline class="st1" points="121.2,30.1 121.2,30.1 121.2,49.8 135.2,49.8 136.7,49.8 142.7,49.8 144.2,49.8 154,49.8 	"/>
	<polyline class="st1" points="158.2,47.3 158.2,30.1 158.2,30.1 	"/>
	<polyline class="st1" points="118.9,31.8 139.7,16.4 160.5,31.8 	"/>
	<polyline class="st1" points="149.5,23.6 149.5,17.5 153.4,17.5 153.4,26.5 	"/>
	<line class="st1" x1="142.7" y1="49.8" x2="142.7" y2="47.7"/>
	<line class="st1" x1="136.7" y1="49.8" x2="136.7" y2="41.5"/>
	<polyline class="st1" points="158.2,53.9 158.2,54.1 146.3,54.1 133.1,54.1 121.2,54.1 121.2,49.8 	"/>
	<g>
		<polyline class="st1" points="141.4,29.8 139.7,29.8 135.8,29.8 135.8,32.6 135.8,35.5 136.7,35.5 		"/>
		<line class="st1" x1="139.7" y1="31" x2="139.7" y2="29.8"/>
		<line class="st1" x1="138.1" y1="32.6" x2="135.8" y2="32.6"/>
	</g>
	<g>
		<path class="st1" d="M140.1,22.1c1.8,0,3.3,1.5,3.3,3.3v1.1H136v-1.1c0-1.8,1.5-3.3,3.3-3.3H140.1z"/>
		<line class="st1" x1="139.7" y1="22.1" x2="139.7" y2="26.4"/>
		<polyline class="st1" points="136.2,24.2 139.7,24.2 143.2,24.2 		"/>
	</g>
	<g>
		<polyline class="st1" points="150.7,29.8 150.7,29.8 154.6,29.8 154.6,32.6 154.6,33.7 		"/>
		<line class="st1" x1="150.7" y1="29.8" x2="150.7" y2="29.8"/>
		<line class="st1" x1="154.6" y1="32.6" x2="153.9" y2="32.6"/>
	</g>
	<g>
		<rect x="124.8" y="29.8" class="st1" width="7.7" height="5.7"/>
		<line class="st1" x1="128.6" y1="29.8" x2="128.6" y2="35.5"/>
		<line class="st1" x1="132.5" y1="32.6" x2="124.8" y2="32.6"/>
	</g>
	<g>
		<rect x="124.8" y="38.3" class="st1" width="7.7" height="5.7"/>
		<line class="st1" x1="128.6" y1="38.3" x2="128.6" y2="43.9"/>
		<line class="st1" x1="132.5" y1="41.1" x2="124.8" y2="41.1"/>
	</g>
	<polyline class="st1" points="144.2,51.9 146.3,51.9 146.3,54.1 	"/>
	<polyline class="st1" points="135.2,51.9 133.1,51.9 133.1,54.1 	"/>
	<polyline class="st1" points="135.2,49.8 135.2,51.9 144.2,51.9 144.2,49.8 	"/>
	<g>
		<path class="st1" d="M153,31.5c-3.8-3.8-10-3.8-13.9,0c-3.8,3.8-3.8,10,0,13.9c3.8,3.8,10,3.8,13.9,0
			C156.8,41.6,156.8,35.3,153,31.5z"/>
		<path class="st1" d="M151.1,33.3c-2.8-2.8-7.4-2.8-10.2,0s-2.8,7.4,0,10.2c2.8,2.8,7.4,2.8,10.2,0
			C153.9,40.7,153.9,36.2,151.1,33.3z"/>
		<path class="st1" d="M143.2,35.7c-1.5,1.5-1.5,4,0,5.6"/>
		<path class="st1" d="M154.4,43.5l10.2,10.2c0,0,0,0,0,0c0.9,0.9,0.9,2.4,0,3.3c-0.9,0.9-2.4,0.9-3.3,0c0,0,0,0,0,0l-10.2-10.2"/>
	</g>
</g>
<g>
	<path class="st0" d="M246.6,256.2h-10.1h-10.1l-3.6-6.5h27.3L246.6,256.2z M236.5,254.8c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
		c-0.8,0-1.4,0.6-1.4,1.4S235.7,254.8,236.5,254.8z"/>
	<path class="st0" d="M250.2,249.8v33.1 M250.2,282.9v5.7h-27.3v-38.8 M237.9,262.2C237.9,262.2,237.9,262.1,237.9,262.2
		c0-0.2,0-0.2,0-0.3c2.1,0.2,5,0,4.2-2.1c-0.7-1.8-3.3,0-4.7,1.1c-0.3-0.2-0.6-0.3-0.9-0.3c-0.8,0-1.4,0.6-1.4,1.4
		c0,0.8,0.6,1.4,1.4,1.4C237.3,263.5,237.9,262.9,237.9,262.2z"/>
	<circle class="st0" cx="236.5" cy="253.3" r="1.4"/>
	<path class="st0" d="M236.5,253.3v1.4v1.4v4.4v1.1v0.2c0.6,0,1.1,0.1,1.4,0.2c2.6,1-2.1,5.6,1.4,5.5"/>
</g>
<g>
	<polyline class="st0" points="121.1,311.4 121.1,310 129,294.2 130.4,294.2 	"/>
	<polyline class="st0" points="159.2,311.4 159.2,310 151.3,294.2 149.9,294.2 	"/>
	<path class="st0" d="M147.7,311.4l-2.2,2.2h-10.8l-2.2-2.2h-11.5v15.8h38.1v-15.8H147.7z"/>
	<polyline class="st0" points="126.1,310 126.1,306.4 154.2,306.4 154.2,310 	"/>
	<polyline class="st0" points="128.3,305 128.3,302.1 152,302.1 152,305 	"/>
	<polyline class="st0" points="130.4,300.6 130.4,297.8 149.9,297.8 149.9,300.6 	"/>
	<polyline class="st0" points="132.6,296.3 132.6,293.4 147.7,293.4 147.7,296.3 	"/>
	<path class="st0" d="M142.7,322.2h-5c-0.8,0-1.4-0.6-1.4-1.4v-2.9h7.9v2.9C144.1,321.6,143.5,322.2,142.7,322.2z"/>
</g>
<path class="st2" d="M276.7,170l-0.2-0.1l0-0.2c-0.1-1.4-0.1-2.8-0.2-4c-1.1-17.1-5.3-33.5-12.5-48.9c-0.6-1.3-1.2-2.4-1.7-3.6
	l-0.1-0.2l0.2-0.1c1-0.9,1.6-2.2,1.6-3.6c0-0.7-0.2-1.4-0.5-2.1l-0.1-0.2l0.3-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.8-0.8,1.5-1.6,2.3-2.6
	c12.9-15.4,11.9-38.6-2.4-52.9c-7.4-7.4-17.2-11.5-27.7-11.5c-9.2,0-17.8,3.1-24.8,8.9c0.5,0.4,1,0.8,1.4,1.3
	c6.7-5.4,14.8-8.3,23.5-8.3c10,0,19.3,3.9,26.3,10.9c6.6,6.6,10.5,15.3,10.9,24.7c0.4,9.3-2.6,18.3-8.4,25.4c-0.8,1-1.6,1.9-2.4,2.7
	l-0.4,0.4l-0.2-0.1c-1.2-0.8-2.7-1.1-4.1-0.7c-0.7,0.2-1.4,0.6-1.9,1.1c-1,0.9-1.5,2.1-1.5,3.5c0,0.5,0.1,1.1,0.3,1.6
	c0.3,0.7,0.7,1.4,1.3,1.9c1.2,1.1,2.8,1.5,4.4,1.1l0.2-0.1l0.1,0.2c0.6,1.2,1.2,2.4,1.7,3.6c7,15.1,11.1,31.3,12.2,48.1
	c0.1,1.3,0.1,2.6,0.2,4l0,0.2l-0.2,0.1c-1.7,0.5-2.9,1.8-3.4,3.4l-0.1,0.2h-62.1c-1.9,0-3.5-1.5-3.6-3.4
	c-2.3-34.1-30.8-60.7-65-60.7c-31.1,0-57.9,22.1-63.9,52.5l0,0.2H76c-2.6,0-4.8,2.1-4.8,4.8s2.1,4.8,4.8,4.8s4.8-2.1,4.8-4.8
	c0-1.8-1-3.4-2.5-4.2l-0.2-0.1l0-0.2c5.7-29.6,31.8-51.1,62.1-51.1c33.2,0,60.9,25.9,63.1,59c0.2,2.9,2.6,5.2,5.5,5.2h62.1l0.1,0.2
	c0.4,1.7,1.7,3,3.4,3.4c0.8,0.2,1.5,0.2,2.3,0c2.2-0.5,3.7-2.4,3.7-4.6C280.4,172.4,278.8,170.5,276.7,170z M261.3,110.9
	c-0.5,0.8-1.4,1.2-2.3,1.2l-0.1,0c-0.4,0-0.8-0.1-1.2-0.3c-0.9-0.4-1.5-1.2-1.6-2.2c0-0.2,0-0.3,0-0.4c0-0.6,0.2-1.1,0.5-1.6
	c0.5-0.8,1.4-1.2,2.3-1.3c0.1,0,0.1,0,0.1,0c0.4,0,0.8,0.1,1.1,0.2c0.9,0.4,1.5,1.2,1.7,2.2c0,0.2,0,0.3,0,0.5
	C261.8,109.8,261.6,110.4,261.3,110.9z M78.2,165.1c0.5,0.5,0.7,1.2,0.7,1.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
	c0-1.4,1-2.6,2.4-2.8C76.5,164,77.5,164.3,78.2,165.1z M276.9,177.1c-0.4,0.2-0.9,0.4-1.4,0.4c-0.5,0-1-0.1-1.4-0.4
	c-0.9-0.5-1.5-1.5-1.5-2.5c0-1,0.6-2,1.5-2.5c0.9-0.5,1.9-0.5,2.8,0c1,0.5,1.5,1.5,1.5,2.5C278.5,175.7,277.9,176.6,276.9,177.1z"/>
<path class="st2" d="M140.3,349.2c10.5,0,20.3-4.1,27.7-11.5c4-4,7-8.7,9-14c0.5-1.3,0.9-2.6,1.2-3.9l0.1-0.2l0.2,0
	c2.6-0.1,4.6-2.2,4.6-4.8c0-2.1-1.4-4-3.4-4.6c-0.8-0.2-1.6-0.3-2.3-0.1c-2.2,0.5-3.8,2.4-3.8,4.7c0,1.8,1,3.5,2.7,4.3l0.2,0.1
	l-0.1,0.2c-0.3,1.3-0.7,2.6-1.2,3.8c-1.9,4.9-4.7,9.3-8.5,13.1c-7,7-16.4,10.9-26.4,10.9c-20.6,0-37.3-16.7-37.3-37.3
	c0-0.6,0-1.6,0.1-2.8c0.1-1.8-1-3.6-2.8-4.1c-5.7-1.8-11.4-4-16.8-6.5c-1.3-0.6-2.5-1.2-3.6-1.7l-0.2-0.1l0.1-0.2
	c0.1-0.4,0.1-0.8,0.1-1.1c0-1.2-0.4-2.3-1.3-3.2l-0.1-0.2l0.1-0.2c0.6-1.1,1.3-2.4,1.8-3.7c6.4-14.7,3.2-32.2-8.2-43.5
	c-11.2-11.2-28.5-14.5-43.1-8.3c0.4,0.5,0.7,1.1,1,1.6c14-5.9,30-2.8,40.8,8c10.9,10.8,14,27,7.9,41.1c-0.5,1.2-1.1,2.4-1.8,3.6
	l-0.1,0.2l-0.2-0.1c-1.8-0.6-3.7-0.1-5,1.2c-0.5,0.5-0.9,1.2-1.1,1.9c-0.1,0.4-0.2,0.9-0.2,1.4c0,1,0.3,1.9,0.9,2.8
	c0.5,0.6,1.1,1.2,1.8,1.5c1.9,1,4.3,0.5,5.7-1.1l0.1-0.2l0.2,0.1c1.1,0.6,2.3,1.2,3.6,1.8c5.6,2.6,11.4,4.9,17.1,6.6
	c0.9,0.3,1.5,1.2,1.5,2.2c-0.1,1.3-0.2,2.3-0.2,3C101.1,331.6,118.7,349.2,140.3,349.2z M175.4,314.9c0-1.1,0.6-2.1,1.6-2.6
	c0.9-0.4,1.9-0.4,2.7,0.1c0.9,0.5,1.4,1.4,1.4,2.4c0,1.3-0.8,2.4-2,2.7c-0.3,0.1-0.6,0.1-0.8,0.1c-0.7,0-1.3-0.2-1.8-0.7
	C175.8,316.5,175.4,315.7,175.4,314.9z M76.7,295.7c-0.5,0.3-1.1,0.5-1.7,0.5c-0.2,0-0.3,0-0.5-0.1c-1-0.2-1.8-0.8-2.2-1.7
	c-0.2-0.4-0.2-0.7-0.2-1.1l0-0.1c0.1-1,0.5-1.8,1.3-2.3c0.5-0.4,1.2-0.5,1.8-0.4c1,0.1,1.9,0.7,2.3,1.6c0.2,0.4,0.3,0.8,0.3,1.3
	C77.9,294.3,77.5,295.2,76.7,295.7z"/>
<path class="st2" d="M262,231.7c-0.5,1-1,2.1-1.6,3.4l-0.1,0.2l-0.2-0.1c-1.6-0.4-3.1,0-4.3,1l-0.2,0.1l-0.2-0.1
	c-1.1-0.6-2.2-1.2-3.4-1.7c-5.1-2.3-10.5-3.4-16.1-3.4c-10.5,0-20.3,4.1-27.7,11.5c-7.4,7.4-11.5,17.2-11.5,27.7
	c0,5.4,1.1,10.7,3.3,15.7c0.5-0.4,1.1-0.7,1.7-0.9c-2-4.7-3-9.6-3-14.7c0-10,3.9-19.3,10.9-26.4c7-7,16.4-10.9,26.4-10.9
	c5.2,0,10.3,1.1,15,3.1c1.2,0.5,2.3,1.1,3.4,1.7l0.2,0.1l-0.1,0.2c-0.2,0.5-0.3,1.1-0.3,1.7c0,1.3,0.5,2.5,1.5,3.5
	c0.6,0.5,1.2,0.9,1.9,1.1c1.5,0.4,3,0.1,4.1-0.7c0.6-0.5,1.2-1.1,1.5-1.8c0.3-0.7,0.5-1.3,0.5-2c0-1.4-0.6-2.7-1.6-3.6l-0.2-0.1
	l0.1-0.2c0.6-1.3,1.2-2.4,1.7-3.4c7.2-15.3,11.4-31.6,12.5-48.6c-0.6,0-1.2,0-1.9-0.1C273.2,200.5,269.1,216.6,262,231.7z
	 M261.8,240.2c-0.1,1-0.7,1.9-1.6,2.3c-0.4,0.2-0.8,0.3-1.2,0.3l-0.1,0c-0.9,0-1.8-0.5-2.3-1.3c-0.3-0.5-0.5-1-0.5-1.6
	c0-0.2,0-0.3,0-0.5c0.2-1,0.8-1.8,1.7-2.2c0.4-0.2,0.7-0.2,1.1-0.2c1,0,1.9,0.5,2.4,1.3c0.3,0.5,0.5,1,0.5,1.6
	C261.8,240,261.8,240.1,261.8,240.2z"/>
<path class="st2" d="M207.7,297.8l0.2-0.1l0.4,0.4c0.9,0.9,1.8,1.7,2.8,2.5c7.1,5.9,15.7,9,24.9,9c10.5,0,20.3-4.1,27.7-11.5
	c7.4-7.4,11.5-17.2,11.5-27.7c0-9.1-3-17.6-8.7-24.6c-0.4,0.5-0.8,1-1.3,1.4c5.3,6.6,8.1,14.6,8.1,23.2c0,10-3.9,19.3-10.9,26.4
	c-7,7-16.4,10.9-26.4,10.9c-8.7,0-16.9-2.9-23.6-8.4c-1-0.8-1.9-1.7-2.8-2.5l-0.3-0.3l0.1-0.2c0.5-0.8,0.7-1.6,0.7-2.5
	c0-0.5-0.1-1-0.2-1.5c-0.2-0.7-0.7-1.4-1.2-2c-1.2-1.2-3.1-1.6-4.7-1.1c-0.8,0.2-1.4,0.6-2,1.2c-0.9,0.9-1.4,2.1-1.4,3.4
	c0,0.3,0,0.6,0.1,0.9l0,0.2l-0.2,0.1c-1.2,0.6-2.5,1.2-3.6,1.8c-5.1,2.4-10.4,4.4-15.7,6.1c-0.6,0.2-1.2,0.1-1.7-0.2
	c-0.5-0.3-0.9-0.8-1-1.4c-4.1-17.8-19.8-30.3-38.2-30.3c-9,0-17.7,3.1-24.6,8.7c-2.6,2.1-4.9,4.5-6.9,7.2c-0.8,1.1-1.6,2.3-2.3,3.6
	l-0.1,0.2l-0.2,0c-0.4-0.1-0.7-0.1-1.1-0.1c-2.6,0-4.8,2.1-4.8,4.8c0,1.6,0.8,3.1,2.2,4c0.7,0.4,1.4,0.7,2.2,0.8c0.1,0,0.3,0,0.4,0
	c2.6,0,4.8-2.1,4.8-4.8c0-1.4-0.6-2.7-1.7-3.6l-0.2-0.2l0.1-0.2c0.7-1.2,1.5-2.4,2.3-3.5c7.1-9.4,17.9-14.8,29.8-14.9
	c17.5,0,32.4,11.9,36.3,28.8c0.3,1.1,1,2.1,2,2.6c1,0.5,2.1,0.7,3.2,0.3c5.4-1.7,10.8-3.8,15.9-6.2c1.3-0.6,2.5-1.2,3.6-1.7l0.2-0.1
	l0.1,0.2c0.9,1.1,2.3,1.8,3.7,1.8C206.2,298.4,207,298.2,207.7,297.8z M105.4,297.9c-0.2,0-0.3,0-0.4,0c-0.8,0-1.6-0.4-2.2-1
	c-0.4-0.5-0.7-1.2-0.7-1.9c0-1.5,1.2-2.8,2.7-2.9c0.1,0,0.1,0,0.2,0c0.9,0,1.7,0.4,2.3,1.2c0.4,0.5,0.6,1.1,0.6,1.7
	C107.9,296.5,106.8,297.7,105.4,297.9z M202.5,293.6c0-0.1,0-0.2,0-0.2l0-0.1c0-0.4,0.2-0.8,0.4-1.2c0.5-0.8,1.4-1.4,2.4-1.4
	c0,0,0,0,0,0c0.5,0,0.9,0.1,1.3,0.3c0.9,0.4,1.5,1.3,1.6,2.2l0,0.1c0,0.1,0,0.2,0,0.2c0,0.3,0,0.5-0.1,0.8c-0.3,0.9-1,1.7-2,1.9
	c-0.3,0.1-0.5,0.1-0.8,0.1c-0.7,0-1.3-0.2-1.8-0.7C202.9,295.3,202.5,294.5,202.5,293.6z"/>
<path class="st2" d="M75.2,179c2.3,34.1,30.8,60.7,65,60.7c16.2,0,31.7-6,43.7-16.8c10.5-9.5,17.5-21.8,20.2-35.7l0-0.2l0.2,0
	c2.4-0.2,4.3-2.3,4.3-4.7c0-2.6-2.1-4.8-4.8-4.8c-2.6,0-4.8,2.1-4.8,4.8c0,1.9,1.2,3.7,3,4.4l0.2,0.1l0,0.2
	c-5.8,29.5-31.9,50.8-62,50.8c-33.2,0-60.9-25.9-63.1-59c-0.2-2.9-2.6-5.2-5.5-5.2H9.4l-0.1-0.2c-0.4-1.7-1.7-3-3.4-3.4
	c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.7,0-1.1,0.1c-2.2,0.5-3.7,2.4-3.7,4.6c0,2.2,1.5,4.1,3.7,4.6l0.2,0.1l0,0.2
	c0.1,1.4,0.1,2.9,0.2,4.3c1.1,17,5.3,33.4,12.5,48.7c0.6,1.3,1.2,2.4,1.7,3.6l0.1,0.2l-0.2,0.1c-1,0.9-1.6,2.2-1.6,3.6
	c0,0.7,0.2,1.4,0.5,2.1l0.1,0.2l-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.8,0.8-1.5,1.6-2.3,2.6c-12.9,15.4-11.9,38.6,2.4,52.9
	c7.4,7.4,17.2,11.5,27.7,11.5c9.2,0,17.8-3.1,24.8-8.9c-0.5-0.4-1-0.8-1.4-1.3c-6.7,5.4-14.8,8.3-23.5,8.3c-10,0-19.3-3.9-26.4-10.9
	c-6.6-6.6-10.5-15.3-10.9-24.7c-0.4-9.3,2.6-18.3,8.4-25.4c0.8-0.9,1.6-1.8,2.4-2.7l0.4-0.4l0.2,0.1c1.2,0.8,2.7,1.1,4.1,0.7
	c0.7-0.2,1.4-0.6,1.9-1.1c1-0.9,1.5-2.1,1.5-3.5c0-0.5-0.1-1.1-0.3-1.6c-0.3-0.7-0.7-1.4-1.3-1.9c-1.2-1.1-2.8-1.5-4.4-1.1l-0.2,0.1
	l-0.1-0.2c-0.6-1.2-1.2-2.4-1.7-3.6c-7-15.1-11.1-31.1-12.2-47.8c-0.1-1.4-0.2-2.9-0.2-4.3l0-0.2l0.2-0.1c1.7-0.5,2.9-1.8,3.4-3.4
	l0.1-0.2h62.1C73.5,175.5,75.1,177.1,75.2,179z M202.2,184.6c-0.8-0.5-1.2-1.4-1.2-2.4c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9
	c0,1.2-0.7,2.2-1.8,2.7c-0.4,0.1-0.7,0.2-1.1,0.2C203.3,185.1,202.7,184.9,202.2,184.6z M19.1,238.3c0.5-0.8,1.4-1.2,2.3-1.2
	c0.5,0,0.9,0.1,1.3,0.3c0.9,0.4,1.5,1.3,1.6,2.2c0,0.2,0,0.3,0,0.4c0,0.6-0.2,1.1-0.5,1.6c-0.5,0.8-1.4,1.2-2.3,1.3
	c-0.1,0-0.1,0-0.1,0c-0.4,0-0.8-0.1-1.1-0.2c-0.9-0.4-1.5-1.2-1.7-2.2c0-0.2,0-0.3,0-0.5C18.6,239.3,18.8,238.8,19.1,238.3z
	 M6.2,177.1c-0.5,0.3-0.9,0.4-1.4,0.4c-0.5,0-0.9-0.1-1.3-0.3c-1-0.5-1.5-1.5-1.5-2.5c0-1.1,0.6-2,1.5-2.5c0.9-0.5,1.9-0.5,2.8,0
	c0.9,0.5,1.5,1.5,1.5,2.5C7.6,175.6,7.1,176.6,6.2,177.1z"/>
<path class="st2" d="M235.8,118c5.4,0,10.6-1.1,15.4-3.2c-0.4-0.5-0.7-1.1-1-1.6c-4.6,1.9-9.4,2.9-14.4,2.9
	c-10,0-19.3-3.9-26.4-10.9c-7.1-7-10.9-16.4-10.9-26.4c0-5.1,1-10.1,3-14.8c0.5-1.2,1.1-2.4,1.8-3.6l0.1-0.2l0.2,0.1
	c1.8,0.6,3.7,0.1,5-1.2c0.5-0.5,0.9-1.2,1.1-1.9c0.1-0.4,0.2-0.9,0.2-1.4c0-1-0.3-1.9-0.9-2.8c-0.5-0.6-1.1-1.2-1.8-1.5
	c-0.7-0.3-1.4-0.5-2.1-0.5c-1.4,0-2.7,0.6-3.6,1.6l-0.1,0.2l-0.2-0.1c-1.1-0.6-2.3-1.1-3.6-1.8c-5.6-2.6-11.4-4.9-17.1-6.6
	c-0.9-0.3-1.5-1.2-1.5-2.2c0.1-1.3,0.2-2.3,0.2-3c0-21.6-17.6-39.2-39.2-39.2c-10.5,0-20.3,4.1-27.7,11.5c-4,4-7,8.7-9,14
	c-0.5,1.3-0.9,2.6-1.2,3.9l-0.1,0.2l-0.2,0c-2.6,0.1-4.6,2.2-4.6,4.8c0,2.1,1.4,4,3.4,4.6c0.8,0.2,1.6,0.3,2.3,0.1
	c2.2-0.5,3.8-2.4,3.8-4.7c0-1.8-1-3.5-2.7-4.3L104,30l0.1-0.2c0.3-1.3,0.7-2.6,1.2-3.8c1.9-4.9,4.7-9.3,8.5-13.1
	c7-7,16.4-10.9,26.4-10.9c20.6,0,37.3,16.7,37.3,37.3c0,0.6-0.1,1.6-0.1,2.8c-0.1,1.8,1,3.6,2.8,4.1c5.7,1.8,11.4,3.9,16.8,6.5
	c1.3,0.6,2.5,1.2,3.6,1.7l0.2,0.1l-0.1,0.2c-0.1,0.4-0.1,0.8-0.1,1.1c0,1.2,0.4,2.3,1.2,3.2l0.1,0.2l-0.1,0.2
	c-0.7,1.2-1.3,2.4-1.8,3.6c-2.2,5-3.3,10.3-3.3,15.8c0,10.5,4.1,20.3,11.5,27.7C215.6,113.9,225.4,118,235.8,118z M104.9,34.3
	c0,1.1-0.6,2.1-1.6,2.6c-0.4,0.2-0.8,0.3-1.3,0.3c-0.5,0-1-0.1-1.5-0.4c-0.9-0.5-1.4-1.4-1.4-2.4c0-1.3,0.8-2.4,2-2.7
	c0.3-0.1,0.6-0.1,0.8-0.1c0.7,0,1.3,0.2,1.8,0.7C104.6,32.7,104.9,33.5,104.9,34.3z M203.6,53.4c0.6-0.4,1.4-0.6,2.2-0.5
	c1,0.2,1.8,0.8,2.2,1.7c0.2,0.4,0.2,0.7,0.2,1.1l0,0.1c-0.1,1-0.5,1.8-1.3,2.3c-0.5,0.3-1,0.5-1.5,0.5c-0.1,0-0.2,0-0.3,0l-0.1,0
	c-1-0.1-1.8-0.7-2.2-1.6c-0.2-0.4-0.3-0.8-0.3-1.2C202.4,54.9,202.9,54,203.6,53.4z"/>
<path class="st2" d="M80.3,63.2c-0.5,0.4-1.1,0.7-1.7,0.9c6,13.9,2.8,30.4-7.9,41.1c-7,7-16.4,10.9-26.4,10.9
	c-5.2,0-10.3-1.1-15-3.1c-1.2-0.5-2.3-1.1-3.4-1.7l-0.2-0.1l0.1-0.2c0.2-0.5,0.3-1.1,0.3-1.7c0-1.3-0.5-2.5-1.5-3.5
	c-0.6-0.5-1.2-0.9-1.9-1.1c-1.5-0.4-3-0.1-4.1,0.7c-0.6,0.5-1.2,1.1-1.5,1.8c-0.3,0.7-0.5,1.3-0.5,2c0,1.4,0.6,2.7,1.6,3.6l0.2,0.1
	l-0.1,0.2c-0.7,1.3-1.2,2.4-1.7,3.4c-7.2,15.2-11.4,31.5-12.5,48.4c0.2,0,0.4,0,0.6,0c0.4,0,0.8,0,1.3,0.1
	c1.2-16.6,5.3-32.7,12.3-47.7c0.5-1,1-2.1,1.6-3.4l0.1-0.2l0.2,0.1c1.6,0.4,3.1,0,4.3-1l0.2-0.1l0.2,0.1c1.1,0.6,2.2,1.2,3.4,1.7
	c5.1,2.3,10.5,3.4,16.1,3.4c10.5,0,20.3-4.1,27.7-11.5C83.4,95.3,86.7,77.9,80.3,63.2z M24.3,109.8c-0.2,1-0.8,1.8-1.7,2.2
	c-0.4,0.2-0.7,0.2-1.1,0.2l-0.1,0c-0.9,0-1.8-0.5-2.3-1.3c-0.3-0.5-0.5-1-0.5-1.6c0-0.1,0-0.2,0-0.3c0.1-1,0.7-1.9,1.6-2.3
	c0.4-0.2,0.8-0.3,1.2-0.3l0.1,0c0.9,0,1.8,0.5,2.3,1.3c0.3,0.5,0.5,1,0.5,1.6C24.3,109.5,24.3,109.7,24.3,109.8z"/>
<path class="st2" d="M177.9,50.2c-0.7-0.4-1.4-0.7-2.2-0.8c-0.1,0-0.3,0-0.4,0c-2.6,0-4.8,2.1-4.8,4.8c0,1.4,0.6,2.7,1.7,3.6
	l0.2,0.2l-0.1,0.2c-0.7,1.2-1.5,2.4-2.3,3.5c-7.1,9.4-17.9,14.8-29.8,14.9c-17.5,0-32.4-11.9-36.3-28.8c-0.3-1.1-1-2.1-2-2.6
	c-1-0.5-2.1-0.7-3.2-0.3c-5.4,1.7-10.8,3.8-15.9,6.2c-1.3,0.6-2.5,1.2-3.6,1.7l-0.2,0.1l-0.1-0.2c-0.9-1.1-2.3-1.8-3.7-1.8
	c-0.8,0-1.6,0.2-2.3,0.6l-0.2,0.1l-0.4-0.4c-0.9-0.9-1.8-1.7-2.8-2.5c-7.1-5.9-15.7-9-24.9-9c-10.5,0-20.3,4.1-27.7,11.5
	C9.3,58.6,5.2,68.4,5.2,78.9c0,9.1,3,17.6,8.7,24.6c0.4-0.5,0.8-1,1.3-1.4c-5.3-6.6-8.1-14.6-8.1-23.2c0-10,3.9-19.3,10.9-26.4
	c7-7,16.4-10.9,26.4-10.9c8.7,0,16.9,2.9,23.6,8.4c1,0.8,1.9,1.7,2.8,2.5l0.3,0.3L71,53c-0.5,0.8-0.7,1.6-0.7,2.5
	c0,0.5,0.1,1,0.2,1.5c0.2,0.7,0.7,1.4,1.2,2c1.2,1.2,3,1.6,4.7,1.1c0.8-0.2,1.4-0.6,2-1.2c0.9-0.9,1.4-2.1,1.4-3.4
	c0-0.3,0-0.6-0.1-0.9l0-0.2l0.2-0.1c1.2-0.6,2.5-1.2,3.6-1.8c5.1-2.4,10.4-4.4,15.7-6.1c0.6-0.2,1.2-0.1,1.7,0.2
	c0.5,0.3,0.9,0.8,1,1.4c4.1,17.8,19.8,30.3,38.2,30.3c0,0,0,0,0,0c9,0,17.7-3.1,24.6-8.7c2.6-2.1,4.9-4.5,6.9-7.2
	c0.8-1.1,1.6-2.3,2.3-3.6l0.1-0.2l0.2,0c3.1,0.7,5.8-1.7,5.8-4.6C180.1,52.6,179.3,51.1,177.9,50.2z M77.9,55.8L77.9,55.8
	c-0.1,0.5-0.2,0.9-0.4,1.3c-0.5,0.8-1.4,1.4-2.4,1.4c0,0,0,0,0,0c-0.5,0-0.9-0.1-1.3-0.3c-0.9-0.4-1.5-1.3-1.6-2.2l0-0.1
	c0-0.1,0-0.2,0-0.2c0-0.3,0-0.5,0.1-0.8c0.3-0.9,1-1.7,1.9-1.9c0.3-0.1,0.5-0.1,0.8-0.1c0.7,0,1.3,0.2,1.8,0.6
	c0.7,0.6,1.1,1.4,1.1,2.2C77.9,55.7,77.9,55.7,77.9,55.8z M175.5,57c-0.1,0-0.1,0-0.2,0c-0.9,0-1.7-0.4-2.3-1.2
	c-0.4-0.5-0.6-1.1-0.6-1.7c0-1.4,1.1-2.7,2.5-2.8l0.1,0c0.9-0.1,1.9,0.3,2.5,1c0.5,0.5,0.7,1.2,0.7,1.9C178.2,55.7,177,57,175.5,57z
	"/>
<g id="_x37_">
	<polyline class="st0" points="227,144.1 227,152 223.9,152 223.9,142.1 227,142.1 	"/>
	<polyline class="st0" points="233.1,144.1 233.1,152 230,152 230,142.1 233.1,142.1 	"/>
	<polyline class="st0" points="239.2,144.1 239.2,152 236.1,152 236.1,142.1 239.2,142.1 	"/>
	<polyline class="st0" points="245.3,144.1 245.3,152 242.2,152 242.2,142.1 245.3,142.1 	"/>
	<polyline class="st0" points="247.9,141.2 247.9,142.1 221.4,142.1 221.4,139.6 247.9,139.6 	"/>
	<rect x="223.2" y="152" class="st0" width="22.8" height="2.5"/>
	<polyline class="st0" points="247.9,156.2 247.9,157 221.4,157 221.4,154.5 247.9,154.5 	"/>
	<polygon class="st0" points="234.6,129.2 222.6,139.6 246.7,139.6 	"/>
	<circle class="st0" cx="234.6" cy="135.2" r="1.8"/>
</g>
<g id="_x31_7">
	<polyline class="st0" points="56.3,155.7 56.3,157.5 33.2,157.5 33.2,153.6 56.3,153.6 	"/>
	<line class="st0" x1="47.1" y1="142.6" x2="47.3" y2="143.4"/>
	<line class="st0" x1="42.1" y1="143.4" x2="42.6" y2="140.8"/>
	<path class="st0" d="M49.2,134.7c0,3.6-2,7-4.5,7c-2.5,0-4.5-3.4-4.5-7"/>
	<path class="st0" d="M49.3,133.9c0,0,0.9-2.2-0.1-3.5c-1-1.3-4.3-2.5-7.5-1C38.3,131,40,134,40,134c4.6,1.3,6-1.5,7.3-1"/>
	<polyline class="st0" points="39.8,144 44.7,150.1 49.7,144 	"/>
	<path class="st0" d="M55.1,153.6H34.3v-4.1c0-3,2.5-5.5,5.5-5.5h9.9c3,0,5.5,2.5,5.5,5.5v1.7"/>
	<polyline class="st0" points="45.5,145.8 46,144 43.5,144 43.8,145.8 	"/>
	<polyline class="st0" points="46,146.9 45.7,146 43.8,146 43.1,148 	"/>
	<line class="st0" x1="31.4" y1="153.6" x2="58" y2="153.6"/>
</g>
<g id="_x31_1">
	<polyline class="st3" points="140.4,205.5 114.7,205.5 114.7,139.4 162.9,139.4 162.9,144.1 	"/>
	<polyline class="st3" points="162.9,153.3 162.9,205.5 152.7,205.5 	"/>
	<line class="st3" x1="146.2" y1="150.4" x2="152.6" y2="150.4"/>
	<line class="st3" x1="125.1" y1="150.4" x2="139.3" y2="150.4"/>
	<line class="st3" x1="125.1" y1="150.4" x2="152.6" y2="150.4"/>
	<line class="st3" x1="146.2" y1="159.3" x2="152.6" y2="159.3"/>
	<line class="st3" x1="125.1" y1="159.3" x2="139.3" y2="159.3"/>
	<line class="st3" x1="125.1" y1="168.3" x2="152.6" y2="168.3"/>
	<line class="st3" x1="138.8" y1="177.3" x2="152.6" y2="177.3"/>
	<line class="st3" x1="125.1" y1="177.3" x2="132.2" y2="177.3"/>
	<line class="st3" x1="125.1" y1="186.2" x2="134.3" y2="186.2"/>
	<line class="st3" x1="125.1" y1="195.2" x2="134.3" y2="195.2"/>
	<polyline class="st3" points="152.3,203.5 153.5,209.8 146.5,207.5 139.6,209.8 141.7,198 	"/>
	<path class="st3" d="M150.4,186.5c-1.1-0.7-2.5-1.1-3.9-1.1c-4,0-7.2,3.2-7.2,7.2c0,4,3.2,7.2,7.2,7.2c4,0,7.2-3.2,7.2-7.2"/>
	<path class="st3" d="M143.9,192.6c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6c0-1.4-1.2-2.6-2.6-2.6
		C145.1,190,143.9,191.2,143.9,192.6z"/>
</g>
<g>
	<g>
		<g>
			<path class="st0" d="M107.1,103.2c0,0.7-0.6,1.3-1.3,1.3h-4.4c-0.7,0-1.3-0.6-1.3-1.3v-20c0-0.7,0.6-1.3,1.3-1.3h4.4
				c0.7,0,1.3,0.6,1.3,1.3V103.2z"/>
		</g>
		<line class="st0" x1="107" y1="85.4" x2="100.3" y2="85.4"/>
		<line class="st0" x1="107" y1="92.7" x2="100.3" y2="92.7"/>
		<g>
			<g>
				<path class="st0" d="M104,100.6c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
					C103.8,100.2,104,100.4,104,100.6z"/>
			</g>
		</g>
	</g>
	<g>
		<path class="st0" d="M109,81.9h4.5c0.7,0,1.3,0.6,1.3,1.3v20c0,0.7-0.6,1.3-1.3,1.3H109"/>
		<line class="st0" x1="114.6" y1="85.4" x2="109" y2="85.4"/>
		<line class="st0" x1="114.6" y1="92.7" x2="109" y2="92.7"/>
		<g>
			<g>
				<path class="st0" d="M111.5,100.6c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
					C111.3,100.2,111.5,100.4,111.5,100.6z"/>
			</g>
		</g>
	</g>
	<g>
		<path class="st0" d="M98.3,81.9h-4.5c-0.7,0-1.3,0.6-1.3,1.3v20c0,0.7,0.6,1.3,1.3,1.3h4.5"/>
		<line class="st0" x1="92.7" y1="85.4" x2="98.3" y2="85.4"/>
		<line class="st0" x1="92.7" y1="92.7" x2="98.3" y2="92.7"/>
		<g>
			<g>
				<path class="st0" d="M95.8,100.6c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3
					C96,100.2,95.8,100.4,95.8,100.6z"/>
			</g>
		</g>
	</g>
</g>
<g>
	<g>
		<g>
			<path class="st0" d="M179.8,86.4v-5.6c0-0.8-0.7-1.5-1.5-1.5h-10.8l-4.3,3.7v16.9c0,0.8,0.7,1.5,1.5,1.5h8.6"/>
			<polyline class="st0" points="168.2,79.5 168.2,84.3 165.5,84.3 			"/>
		</g>
	</g>
	<path class="st0" d="M179.8,100.5"/>
	<path class="st0" d="M179.8,98.9c-1.9,0-3.6,1-4.5,2.5l9.1,0C183.4,99.9,181.8,98.9,179.8,98.9z"/>
	<line class="st0" x1="179.8" y1="97.4" x2="179.8" y2="88"/>
	<path class="st0" d="M187.5,88.4c0-0.1-0.4,1.3-2.3,0.6l-0.3-0.1c-1.3-0.5-2.8-0.9-5-0.9h0c-2.2,0-3.7,0.3-5,0.9l-0.3,0.1
		c-2,0.7-2.4-0.7-2.3-0.6"/>
	<g>
		<g>
			<path class="st0" d="M176.8,94.6c0,1.1-1.2,2-2.8,2c-1.5,0-2.8-0.9-2.8-2c0-1.1,2.8-5.3,2.8-5.3S176.8,93.5,176.8,94.6z"/>
			<line class="st0" x1="171.5" y1="93.8" x2="176.5" y2="93.8"/>
		</g>
		<g>
			<path class="st0" d="M188.4,94.6c0,1.1-1.2,2-2.8,2c-1.5,0-2.8-0.9-2.8-2c0-1.1,2.8-5.3,2.8-5.3S188.4,93.5,188.4,94.6z"/>
			<line class="st0" x1="183.2" y1="93.8" x2="188.1" y2="93.8"/>
		</g>
	</g>
</g>
<g>
	<path class="st0" d="M42.7,212.1h-4c-1.4,0-2.6-1.2-2.6-2.6v-0.9c0-1.4,1.2-2.6,2.6-2.6h15v-17.2H39.6c-1.9,0-3.4,1.5-3.4,3.4v16.3
		"/>
	<line class="st0" x1="53.8" y1="212.1" x2="46.6" y2="212.1"/>
	<line class="st0" x1="39.2" y1="188.9" x2="39.2" y2="205.9"/>
	<polyline class="st0" points="46.6,209.1 46.6,215.3 44.7,214 42.8,215.3 42.8,209.1 	"/>
	<line class="st0" x1="42.2" y1="209" x2="53.8" y2="209"/>
	<line class="st0" x1="38.9" y1="209" x2="52" y2="209"/>
</g>
<g>
	<g>
		<path class="st0" d="M173.9,246.3c0-1.7,1.4-3.2,3.2-3.2"/>
	</g>
	<line class="st0" x1="169" y1="264.6" x2="185.2" y2="264.6"/>
	<path class="st0" d="M185.8,261.9v-4.4c0-0.9-0.8-1.7-1.7-1.7l-3.8,0c-1.7-2.4,0.3-4.9,0.3-4.9c1-1.4,1.6-3.3,1.6-3.3
		c0.1-0.4,0.2-0.8,0.2-1.3c0-2.9-2.3-5.2-5.2-5.2h-0.1c-2.9,0-5.2,2.3-5.2,5.2c0,0.4,0.1,0.9,0.2,1.3c0,0,0.6,1.8,1.6,3.3
		c0,0,2,2.5,0.3,4.9l-3.8,0c-0.9,0-1.7,0.8-1.7,1.7v4.4H185.8z"/>
</g>
<g>
	<g>
		<path class="st0" d="M98.5,245.7h-2c-0.6,0-1.1-0.5-1.1-1.1v-3.4c0-0.6,0.5-1.1,1.1-1.1h2c0.6,0,1.1,0.5,1.1,1.1v3.4
			C99.6,245.2,99.2,245.7,98.5,245.7z"/>
		<path class="st0" d="M94.9,256.7c-2.1,0-2.2-1.8-2.2-1.8v-4.3c0-2.1,1.7-3.7,3.7-3.7h3.9h6.7c0,0-0.3,2.8-2.8,2.8
			c-2.5,0-4.2,0-4.2,0v14h-5.3V251"/>
		<line class="st0" x1="97.5" y1="256.2" x2="97.5" y2="263.6"/>
	</g>
	<polyline class="st0" points="101,242.9 115.1,242.9 115.1,254.5 102.8,254.5 	"/>
	<line class="st0" x1="107" y1="254.8" x2="107" y2="263.6"/>
	<polyline class="st0" points="110.4,263.7 106.9,254.7 103.4,263.7 	"/>
</g>
<g>
	<rect x="218.8" y="191.5" transform="matrix(0.866 0.5 -0.5 0.866 128.3223 -83.7515)" class="st0" width="3.2" height="12.2"/>
	<rect x="243.6" y="191.4" transform="matrix(-0.866 0.5 -0.5 -0.866 556.4139 245.9165)" class="st0" width="3.2" height="12.2"/>
	<path class="st0" d="M222.2,206.8L222.2,206.8c-0.6-0.5-0.7-1.4-0.2-2l0.5-0.6c0.5-0.6,1.4-0.7,2-0.2l0,0c0.6,0.5,0.7,1.4,0.2,2
		l-0.5,0.6C223.7,207.2,222.8,207.2,222.2,206.8z"/>
	<path class="st0" d="M224.3,208.6L224.3,208.6c-0.6-0.5-0.7-1.4-0.2-2l2-2.5c0.5-0.6,1.4-0.7,2-0.2l0,0c0.6,0.5,0.7,1.4,0.2,2
		l-2,2.5C225.8,209,224.9,209.1,224.3,208.6z"/>
	<path class="st0" d="M226.4,210.5L226.4,210.5c-0.6-0.5-0.7-1.4-0.2-2l1.7-2c0.5-0.6,1.4-0.7,2-0.2l0,0c0.6,0.5,0.7,1.4,0.2,2
		l-1.7,2C227.9,210.9,227,211,226.4,210.5z"/>
	<path class="st0" d="M228.6,212.2L228.6,212.2c-0.6-0.5-0.7-1.4-0.2-2l1.1-1.4c0.5-0.6,1.4-0.7,2-0.2l0,0c0.6,0.5,0.7,1.4,0.2,2
		l-1.1,1.4C230.1,212.6,229.2,212.7,228.6,212.2z"/>
	<path class="st0" d="M231.2,211.2c0,0,4.1,2.4,5.8-0.4"/>
	<path class="st0" d="M233.2,208.9c0,0,3.9,2.5,5,1.9c1.1-0.6,1.1-1.1,1.1-1.8"/>
	<line class="st0" x1="220.1" y1="201.5" x2="222.4" y2="204.4"/>
	<path class="st0" d="M234.7,206.3c0,0,6.4,5.6,6.4,0.5"/>
	<path class="st0" d="M236.1,203.7c0,0,5.5,4.4,6.8,3.1c0,0,2.1-1.7-1.2-3.6c-3.3-1.9-8.7-5.5-8.7-5.5l-1.5,0.4c0,0-1.2,1.9-3.5,2.3
		c-0.7,0.1-1.2-0.5-1-1.2c0.4-1,1.1-2.6,2.9-4.3c0,0,2-1.8,7.4-0.5c2,0.4,3,1.1,4.4,0.3"/>
	<path class="st0" d="M246,202.1c0,0-2.7,2.1-2.9,2.2"/>
	<path class="st0" d="M224.3,194.2c0,0,2.6,1.9,4.7,1.7"/>
</g>
</svg>


</template>

<script>
  export default {
    name: "LegalProcessAutomationChart"
  }
</script>

<style scoped>

  .st0 {
    fill: none;
    stroke: #072C5C;
    stroke-width: 1.1549;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: none;
    stroke: #072C5C;
    stroke-width: 0.9624;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st2 {
    fill: #072C5C;
  }

  .st3 {
    fill: none;
    stroke: #072C5C;
    stroke-width: 1.5399;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
</style>
