<template>
  <div class="root_cont">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-sm-12">
          <h4 style="font-weight: 800;">{{ t.head_1 }}</h4>
          <p class="mt-4">
            {{ t.h_1 }}
          </p>
          <p class="mt-4">
            {{ t.h_2 }}
          </p>
        </div>
        <div class="col-md-5 col-sm-12">
          <LegalProcessAutomationChart></LegalProcessAutomationChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head_1": "Legal Process Automation",
    "h_1": "We have worked with experts to create a legal manual detailing every conceivable obstacle to establishing clean title to land ahead of sale. To streamline this activity, we are automating vital elements of the process and employing a range of techniques such as: user document classification, legal paperwork generation, smart forms and automated communication between all parties.",
    "h_2": "In doing this, we will save thousands of hours of manual legal work, instead of providing our legal team with all the information they need to effect and manage the process through the courts as efficiently as possible."
  },
  "hr": {
    "head_1": "Automatizacija pravnih procesa",
    "h_1": "U suradnji sa stručnjacima, napravili smo pravni priručnik koji detaljno ocrtava svaku moguću prepreku na putu do čistog vlasništva prije same prodaje zemljišta. Radi veće učinkovitosti, automatiziramo vitalne dijelove pravnog procesa i usvajamo niz metoda, poput klasifikacije dokumenata korisnika, pripreme pravne dokumentacije, pametnih obrazaca i automatizirane komunikacije između svih strana.",
    "h_2": "Umjesto da našem pravnom timu potrebnu dokumentaciju pribavljamo ručno, materijale dostavljamo automatiziranim postupcima, kako bismo što učinkovitije pokretali i vodili sudske procese. Time štedimo tisuće radnih sati."
  },
  "es": {
    "head_1": "Automatización de procesos legales",
    "h_1": "Hemos trabajado con expertos para crear un manual legal que detalla cada obstáculo concebible al establecimiento de un título limpio del terreno antes de la venta. Para optimizar esta actividad, automatizamos los elementos vitales del proceso y empleamos una serie de técnicas, como la clasificación de documentos de usuario, generación de documentos legales, formularios inteligentes y comunicación automatizada entre todas las partes.",
    "h_2": "En lugar de proporcionar a nuestro equipo legal todos los documentos de manera manual, usamos procesos automatizados para proporcionar los materiales y para presentar y gestionar los procesos en los tribunales de la manera más eficaz posible. Haciéndolo ahorramos miles de horas de trabajo."
  }
}
//</i18n>

import LegalProcessAutomationChart from "./charts/LegalProcessAutomationChart";
export default {
  name: "LegalProcessAutomation",
  components: { LegalProcessAutomationChart },
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.root_cont {
  background-color: #fffff3;
  color: rgb(4, 43, 93);
  padding: 60px 0;
}
</style>
