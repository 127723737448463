<template>
  <div class="root_cont">
    <div class="container">
      <div class="row text-left">
        <div class="col-md-5 col-sm-12">
          <div class="chart_cont pl-5 pr-5">
            <img src="/static/images/SemanticSearch.svg" alt="" />
          </div>
        </div>
        <div class="col-md-7 col-sm-12">
          <h4 style="font-weight: 800;">
            {{ t.head_1 }}
          </h4>
          <p class="mt-4">
            {{ t.h_1 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head_1": "Semantic Search",
    "h_1": "Historical and geographic variations of Croatian names, as well as simple typos present a significant challenge when searching for credible links between records. Using our core NLP modules and an embedded representation of semantic similarity of records, we have developed a proprietary Semantic Search Engine for retrieving information on possible land titles in Croatia from the data our users provide us."
  },
  "hr": {
    "head_1": "Semantičko pretraživanje",
    "h_1": "Povijesne i geografske varijacije hrvatskih imena, kao i najobičnije greške u pisanju, značajan su izazov kada govorimo o vjerodostojnom povezivanju podataka iz različitih registara. Koristeći naše module za obradu prirodnog teksta i vektorskim prikazom semantičke sličnosti zapisa stvorili smo vlastitu semantičku tražilicu. Njome pronalazimo podatke o mogućim vlasničkim pravima na temelju informacija koje nam omoguće naši korisnici."
  },
  "es": {
    "head_1": "Búsqueda semántica",
    "h_1": "Las variaciones históricas y geográficas de los nombres croatas, así como los simples errores ortográficos, presentan un desafío significativo al buscar vínculos creíbles entre registros. Usando nuestros módulos para el procesamiento del lenguaje natural y una representación integrada de similitud semántica de registros, hemos desarrollado un motor propio de búsqueda semántica para recuperar información sobre posibles títulos de terrenos en Croacia a partir de los datos que nos proporcionan nuestros usuarios."
  }
}
//</i18n>

export default {
  name: "SemanticSearch",
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.root_cont {
  background-color: rgb(4, 43, 93);
  color: #fffff3;
  padding: 60px 0;
}
</style>
